export const BANNER_IMAGE_LIST_REQUEST = "BANNER_IMAGE_LIST_REQUEST";
export const BANNER_IMAGE_LIST_SUCCESS = "BANNER_IMAGE_LIST_SUCCESS";
export const BANNER_IMAGE_LIST_FAIL = "BANNER_IMAGE_LIST_FAIL";

export const BANNER_IMAGE_DELETE_REQUEST = "BANNER_IMAGE_DELETE_REQUEST";
export const BANNER_IMAGE_DELETE_SUCCESS = "BANNER_IMAGE_DELETE_SUCCESS";
export const BANNER_IMAGE_DELETE_FAIL = "BANNER_IMAGE_DELETE_FAIL";

export const BANNER_IMAGE_CREATE_REQUEST = "BANNER_IMAGE_CREATE_REQUEST";
export const BANNER_IMAGE_CREATE_SUCCESS = "BANNER_IMAGE_CREATE_SUCCESS";
export const BANNER_IMAGE_CREATE_FAIL = "BANNER_IMAGE_CREATE_FAIL";
export const BANNER_IMAGE_CREATE_RESET = "BANNER_IMAGE_CREATE_RESET";
