const cities = [
  "Arima",
  "Port of Spain",
  "San Fernando",
  "Tobago",
  "Chaguanas",
  "Couva",
  "Tabaquite",
  "Talparo",
  "Diego Martin",
  "Mayaro",
  "Rio Claro",
  "Penal",
  "Debe",
  "Point Fortin",
  "Princes Town",
  "San Juan",
  "Laventille",
  "St Helena",
  "Sangre Grande",
  "Siparia",
  "Tunapuna",
  "Piarco",
  "Other",
];

export default cities;
