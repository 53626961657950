export const ITEM_LIST_REQUEST = "ITEM_LIST_REQUEST";
export const ITEM_LIST_SUCCESS = "ITEM_LIST_SUCCESS";
export const ITEM_LIST_FAIL = "ITEM_LIST_FAIL";

export const ITEM_DETAIL_REQUEST = "ITEM_DETAIL_REQUEST";
export const ITEM_DETAIL_SUCCESS = "ITEM_DETAIL_SUCCESS";
export const ITEM_DETAIL_FAIL = "ITEM_DETAIL_FAIL";

export const ITEM_DELETE_REQUEST = "ITEM_DELETE_REQUEST";
export const ITEM_DELETE_SUCCESS = "ITEM_DELETE_SUCCESS";
export const ITEM_DELETE_FAIL = "ITEM_DELETE_FAIL";

export const ITEM_UPDATE_REQUEST = "ITEM_UPDATE_REQUEST";
export const ITEM_UPDATE_SUCCESS = "ITEM_UPDATE_SUCCESS";
export const ITEM_UPDATE_FAIL = "ITEM_UPDATE_FAIL";
export const ITEM_UPDATE_RESET = "ITEM_UPDATE_RESET";

export const ITEM_CREATE_REQUEST = "ITEM_CREATE_REQUEST";
export const ITEM_CREATE_SUCCESS = "ITEM_CREATE_SUCCESS";
export const ITEM_CREATE_FAIL = "ITEM_CREATE_FAIL";
export const ITEM_CREATE_RESET = "ITEM_CREATE_RESET";
