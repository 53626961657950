const prices = [
  "$100000 - $1000000",
  "$1000000 - $1500000",
  "$1500000 - $2000000",
  "$2000000 - $2500000",
  "$1000 - $5000",
  "$5000 - $100000",
];

export default prices;
